import {useTranslation} from "../../misc/Hooks";
import {getBenefitsOrder, getMarketImage, isEnabledByMarket} from "../../utils/MarketConfig";


export default (props) => {
    const t = useTranslation();

    const title = t('benefits_info.title');

    const list = t('benefits_info.list');

    const text1 = t('benefits_info.text1');
    const text2 = t('benefits_info.text2');
    const line1 = t('benefits_info.line1');
    const line2 = t('benefits_info.line2');
    const line3 = t('benefits_info.line3');
    const line4 = t('benefits_info.line4');

    return (
        <div className="benefitsInfo --mb4">
            {!!title && (
                <h2 className="benefitsInfo__title par-1 --semiBold --mb3">{title}</h2>
            )}
            {!!text1 && (
                <p className="par-2 --mb3" dangerouslySetInnerHTML={{__html: text1}} />
            )}
            {!!list && (
                <ol className="par-2 --mb3" dangerouslySetInnerHTML={{__html: list}}/>
            )}
            {!!(line1 || line2 || line3 || line4) && (
                <ul className="par-2 --mb3">
                    {!!line1 && (
                        <li>
                            <img src={require('../../assets/img/icons/egifcard.png').default} alt=""/>
                            {line1}
                        </li>
                    )}
                    {!!line2 && (
                        <li>
                            <img src={require('../../assets/img/icons/present.png').default} alt=""/>
                            {line2}
                        </li>
                    )}
                    {!!line3 && (
                        <li>
                            <img src={require('../../assets/img/icons/beans.png').default} alt=""/>
                            {line3}
                        </li>
                    )}
                    {!!line4 && (
                        <li>
                            <img src={require('../../assets/img/icons/nieuws.png').default} alt=""/>
                            {line4}
                        </li>
                    )}
                </ul>
            )}
            {isEnabledByMarket("customBenefitsInfo") && (
                <div className="gridSm -full">
                    <div className="benefitsInfo__custom">
                        <img src={getMarketImage("customBenefitsInfo")} alt="" style={{maxWidth: '100%'}}/>
                        <div
                            dangerouslySetInnerHTML={{__html: t('benefits_info.custom')}}
                        />
                    </div>
                </div>
            )}
            {!!text2 && (
                <p className="par-2 --pt4" dangerouslySetInnerHTML={{__html: text2}} />
            )}
        </div>
    )
}
