import React, {useEffect, useRef, useState} from 'react';
import {Controller, FormProvider, useForm} from "react-hook-form";
import {MyAsyncSelect, MyCheckbox, MyInput, MyInputMask, MySelect, RecaptchaV2} from "./inputs/_index";
import Patterns from "../../utils/Patterns";
import {Button} from "../buttons/_index";
import {useCountryConfig, useLocalizeFile, useLocalizeObject, useMarketConfig, useTranslation} from "../../misc/Hooks";
import {getFieldFormat, scrollVertically} from "../../misc/Helpers";
import {BenefitsInfo, TermsInfo} from "../misc/_index";
import {MyLink} from "../../misc/_index";
import {useSelector} from "react-redux";
import {FormFragmentBenefits} from "./fragments";
import {useCivilityOptions, useCountryOptions, useLanguages, useMemberPhoneMask, useStackOptions} from "../../hooks";
import * as MainActions from '../../actions/MainActions'
import {isEnabledByMarket} from "../../utils/MarketConfig";

export default (props) => {
    const t = useTranslation();
    const localizeFile = useLocalizeFile();
    const localizeObject = useLocalizeObject()
    const countryConfig = useCountryConfig();
    const marketConfig = useMarketConfig();
    const civilityOptions = useCivilityOptions()
    const countryOptions = useCountryOptions()
    const professionOptions = useStackOptions('professions')
    const regionOptions = useStackOptions('regions')
    const languages = useLanguages();
    const store = useSelector(({main}) => ({
        civilities: main.civilities,
    }))
    const [citiesState, setCitiesState] = useState({loading: false, data: []})
    const [customCitiesState, setCustomCitiesState] = useState({loading: false, data: []})



    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            memberAddress: props.customer?.memberAddress ?? null,
            memberPhone: props.customer?.phone ?? '',
            anotherAddress: !props.nespressoMember ? true : !!props.customer?.anotherAddress,

            companyAddress: !!props.customer?.companyAddress,
            companyName: props.customer?.companyName ?? '',
            taxId: props.customer?.taxId ?? '',
            profession: props.customer?.profession ? professionOptions.find(props.customer.profession.value) : null,
            title: props.customer?.title ? civilityOptions.find(props.customer.title.value) : null,
            name: props.customer?.name ?? '',
            surname: props.customer?.surname ?? '',
            postcode: props.customer?.postcode ?? '',
            city: props.customer?.city ?? '',
            address: props.customer?.address ?? '',
            address2: props.customer?.address2 ?? '',
            region: props.customer?.region ? regionOptions.find(props.customer.region.value) : null,
            country: props.customer?.country ?? isEnabledByMarket("selectDefaultFirstCountry") ? countryOptions.countryOptions[0] : null,
            lang: props.customer?.lang ?? isEnabledByMarket("selectDefaultLang") ? countryOptions.findLanguage(languages.defaultLang) : null,
            phone: props.customer?.phone ?? '',
            phoneMobile: props.customer?.phoneMobile ?? '',
            phoneHome: props.customer?.phoneHome ?? '',
            email: props.customer?.email ?? '',
            reEmail: props.customer?.reEmail ?? '',
            password: props.customer?.password ?? '',
            rePassword: props.customer?.rePassword ?? '',

            withCustomAddress: !!props.customer?.withCustomAddress,
            customCompanyAddress: !!props.customer?.customCompanyAddress,
            customCompanyName: props.customer?.customCompanyName ?? '',
            customTaxId: props.customer?.customTaxId ?? '',
            customTitle: isEnabledByMarket('hideCivility') ? null : props.customer?.customTitle ? civilityOptions.find(props.customer.title.value) : null,
            customName: props.customer?.customName ?? '',
            customSurname: props.customer?.customSurname ?? '',
            customPostcode: props.customer?.customPostcode ?? '',
            customCity: props.customer?.customCity ?? '',
            customAddress: props.customer?.customAddress ?? '',
            customAddress2: props.customer?.customAddress2 ?? '',
            customRegion: props.customer?.customRegion ? regionOptions.find(props.customer.customRegion.value) : null,
            customPhone: props.customer?.customPhone ?? '',
            customPhoneMobile: props.customer?.customPhoneMobile ?? '',
            customPhoneHome: props.customer?.customPhoneHome ?? '',

            benefitsEmail: !!props.customer?.benefitsEmail,
            benefitsPhone: !!props.customer?.benefitsPhone,

            benefits: {
                email: props.nespressoMember ? props.nespressoMember?.consents?.contactByEmail ?? 0
                    : [3, 4].includes(props.plan ? props.plan.product_plan_marketing_email_visible : props.promotion?.promotion_marketing_email_visible),
                mobile: props.nespressoMember ? props.nespressoMember?.consents?.mobileNotification ?? 0
                    : [3, 4].includes(props.plan ? props.plan.product_plan_marketing_mobile_visible : props.promotion?.promotion_marketing_mobile_visible),
                phone: props.nespressoMember ? props.nespressoMember?.consents?.contactByPhone ?? 0
                    : [3, 4].includes(props.plan ? props.plan.product_plan_marketing_phone_visible : props.promotion?.promotion_marketing_phone_visible),
                post: props.nespressoMember ? props.nespressoMember?.consents?.contactByPost ?? 0
                    : [3, 4].includes(props.plan ? props.plan.product_plan_marketing_post_visible : props.promotion?.promotion_marketing_post_visible),
                ...props.customer?.benefits
            },

            termsAndConditions: !!props.customer?.termsAndConditions,
            privacyPolicy: !!props.customer?.privacyPolicy,

            recaptchaToken: '',
        }
    });

    const memberPhoneMask = useMemberPhoneMask(formMethods)

    const memberAddress = formMethods.watch('memberAddress');
    const anotherAddress = formMethods.watch('anotherAddress');
    const companyAddress = formMethods.watch('companyAddress');
    const withCustomAddress = formMethods.watch('withCustomAddress');
    const customCompanyAddress = formMethods.watch('customCompanyAddress');
    const memberPhone = formMethods.watch('memberPhone');
    const postcode = formMethods.watch('postcode');
    const customPostcode = formMethods.watch('customPostcode');

    const [deliveryOptions, setDeliveryOptions] = useState([]);

    const numberPattern = /^[0-9]{7,11}$/;

    const fieldsConfigs = {
        taxId: getFieldFormat(countryConfig.custom_format?.custom_tax_id_format),
        companyTaxId: getFieldFormat(countryConfig.custom_format?.custom_company_tax_id_format),
        name: getFieldFormat(countryConfig.custom_format?.custom_firstname_format),
        surname: getFieldFormat(countryConfig.custom_format?.custom_lastname_format),
        postcode: getFieldFormat(countryConfig.custom_format?.custom_postcode_format),
        city: getFieldFormat(countryConfig.custom_format?.custom_city_format),
        address: getFieldFormat(countryConfig.custom_format?.custom_address1_format),
        address2: getFieldFormat(countryConfig.custom_format?.custom_address2_format),
        phone: getFieldFormat(countryConfig.custom_format?.custom_phone_format),
        phoneMobile: getFieldFormat(countryConfig.custom_format?.custom_phone_mobile_format),
        phoneHome: getFieldFormat(countryConfig.custom_format?.custom_phone_home_format),
    }

    const rules = {
        memberAddress: {
            validate: v => {
                if (v?.value || anotherAddress) {
                    return true;
                }
                return t('validation.this_or', t('deliver_to_another_address'));
            },
        },
        anotherAddress: {
            validate: v => {
                if (v || memberAddress?.value) {
                    return true;
                }
                return t('validation.this_or', t('choose_your_delivery_address'));
            },
        },

        companyAddress: {},
        companyName: {
            required: {value: !!companyAddress, message: t('validation.required')},
        },
        taxId: {
            required: {value: !!fieldsConfigs.taxId.required, message: t('validation.required')},
            pattern: {
                value: fieldsConfigs.taxId.pattern,
                message: t('validation.invalid_tax_id') || t('validation.invalid')

            }, maxLength: {
                value: fieldsConfigs.taxId.max_length,
                message: t('validation.max_length', fieldsConfigs.taxId.max_length)
            }
        },
        companyTaxId: {
            required: {value: !!fieldsConfigs.companyTaxId.required, message: t('validation.required')},
            pattern: {
                value: fieldsConfigs.companyTaxId.pattern,
                message: t('validation.invalid_tax_id') || t('validation.invalid')
            },
            maxLength: {
                value: fieldsConfigs.companyTaxId.max_length,
                message: t('validation.max_length', fieldsConfigs.companyTaxId.max_length)
            }
        },
        profession: {
            required: {value: anotherAddress, message: t('validation.required')}
        },
        title: {
            required: {value: !companyAddress, message: t('validation.required')},
        },
        name: {
            required: {value: anotherAddress, message: t('validation.required')},
            pattern: {
                value: fieldsConfigs.name.pattern ?? Patterns.onlyText,
                message: t('validation.invalid_name') || t('validation.invalid')
            },
            maxLength: {
                value: fieldsConfigs.name.max_length,
                message: t('validation.max_length', fieldsConfigs.name.max_length)
            }
        },
        surname: {
            required: {value: anotherAddress, message: t('validation.required')},
            pattern: {
                value: fieldsConfigs.surname.pattern ?? Patterns.onlyText,
                message: t('validation.invalid_surname') || t('validation.invalid')
            },
            maxLength: {
                value: fieldsConfigs.surname.max_length,
                message: t('validation.max_length', fieldsConfigs.surname.max_length)
            }
        },
        postcode: {
            required: {value: anotherAddress, message: t('validation.required')},
            pattern: {
                value: fieldsConfigs.postcode.pattern,
                message: t('validation.invalid_postcode') || t('validation.invalid')
            },
            maxLength: {
                value: fieldsConfigs.postcode.max_length,
                message: t('validation.max_length', fieldsConfigs.postcode.max_length)
            }
        },
        city: {
            required: {value: anotherAddress, message: t('validation.required')},
            pattern: {
                value: fieldsConfigs.city.pattern,
                message: t('validation.invalid_city') || t('validation.invalid')
            },
            maxLength: {
                value: fieldsConfigs.city.max_length,
                message: t('validation.max_length', fieldsConfigs.city.max_length)
            }
        },
        address: {
            required: {value: anotherAddress, message: t('validation.required')},
            pattern: {
                value: fieldsConfigs.address.pattern ?? Patterns.address,
                message: t('validation.invalid_address') || t('validation.invalid')
            },
            maxLength: {
                value: fieldsConfigs.address.max_length,
                message: t('validation.max_length', fieldsConfigs.address.max_length)
            }
        },
        address2: {
            required: {value: anotherAddress && fieldsConfigs.address2.required, message: t('validation.required')},
            pattern: {
                value: fieldsConfigs.address2.pattern,
                message: t('validation.invalid_address') || t('validation.invalid')
            },
            maxLength: {
                value: fieldsConfigs.address2.max_length,
                message: t('validation.max_length', fieldsConfigs.address2.max_length),
            }
        },
        region: {
            required: {value: anotherAddress, message: t('validation.required')}
        },
        country: {
            required: {value: anotherAddress, message: t('validation.required')}
        },
        lang: {
            required: {value: anotherAddress, message: t('validation.required')}
        },
        phone: {
            required: {value: anotherAddress, message: t('validation.required')},
            pattern: {
                value: fieldsConfigs.phone.pattern,
                message: t('validation.invalid_phone') || t('validation.invalid')
            },
            maxLength: {
                value: fieldsConfigs.phone.max_length,
                message: t('validation.max_length', fieldsConfigs.phone.max_length)
            }
        },
        phoneMobile: {
            required: {value: anotherAddress && fieldsConfigs.phoneMobile.required, message: t('validation.required')},
            pattern: {
                value: fieldsConfigs.phoneMobile.pattern,
                message: t('validation.invalid_phone_mobile') || t('validation.invalid')
            },
            maxLength: {
                value: fieldsConfigs.phoneMobile.max_length,
                message: t('validation.max_length', fieldsConfigs.phoneMobile.max_length)
            }
        },
        phoneHome: {
            required: {value: anotherAddress && fieldsConfigs.phoneHome.required, message: t('validation.required')},
            pattern: {
                value: fieldsConfigs.phoneHome.pattern,
                message: t('validation.invalid_phone_home') || t('validation.invalid')
            },
            maxLength: {
                value: fieldsConfigs.phoneHome.max_length,
                message: t('validation.max_length', fieldsConfigs.phoneHome.max_length)
            }
        },
        email: {
            required: {value: anotherAddress, message: t('validation.required')},
            pattern: {value: Patterns.email, message: t('validation.invalid_email') || t('validation.invalid')},
            maxLength: {
                value: 50,
                message: t('validation.max_length', 50),
            }
        },
        reEmail: {
            required: {value: anotherAddress, message: t('validation.required')},
            validate: v => v === formMethods.watch('email') ? true : t('validation.same_as', t('email')),
            maxLength: {
                value: 50,
                message: t('validation.max_length', 50),
            }
        },
        password: {
            required: {value: anotherAddress, message: t('validation.required')},
            pattern: {value: Patterns.password, message: t('validation.password')},
        },
        rePassword: {
            required: {value: anotherAddress, message: t('validation.required')},
            validate: v => v === formMethods.watch('password') ? true : t('validation.same_as', t('password')),
        },

        customAddress: {},
        customCompanyName: {
            required: {value: !!customCompanyAddress, message: t('validation.required')},
        },
        customTitle: {
            required: {
                value: isEnabledByMarket('hideCivility') ? false : !customCompanyAddress,
                message: t('validation.required')
            },
        },

        benefitsEmail: {},
        benefitsPhone: {},
        termsAndConditions: {
            required: {value: true, message: t('validation.are_required', t('terms_and_conditions'))}
        },
        privacyPolicy: {
            required: {value: true, message: t('validation.is_required', t('privacy_policy'))}
        },

        recaptchaToken: {
            required: {value: true, message: t('validation.required')}
        },
    }

    const termsFileUrl = localizeFile(props.plan ? props.plan.product_files : props.promotion?.promotion_files, 'regulations');
    const privacyFileUrl = localizeFile(props.plan ? props.plan.product_files : props.promotion?.promotion_files, 'privacy_policy')
        ?? localizeFile(marketConfig.files, 'privacy_policy');

    useEffect(async () => {
        if (marketConfig.post_code_automatic_city) {
            if (postcode?.toString().match(fieldsConfigs.postcode.pattern)) {
                setCitiesState(prev => ({...prev, loading: true}))
                const cities = await getCities(postcode)
                if (cities.length) {
                    setCitiesState({loading: false, data: cities})
                } else {
                    setCitiesState(prev => ({...prev, loading: false}))
                    formMethods.setError('postcode', {
                        type: 'invalid',
                        message: t('validation.invalid_postcode') || t('validation.invalid')
                    })
                }
            } else {
                formMethods.setValue('city', '')
                setCitiesState({loading: false, data: []})
            }
        }
    }, [postcode])

    useEffect(async () => {
        if (marketConfig.post_code_automatic_city) {
            if (customPostcode?.toString().match(fieldsConfigs.postcode.pattern)) {
                setCustomCitiesState(prev => ({...prev, loading: true}))
                const cities = await getCities(customPostcode)
                if (cities.length) {
                    setCustomCitiesState({loading: false, data: cities})
                } else {
                    setCustomCitiesState(prev => ({...prev, loading: false}))
                    formMethods.setError('customPostcode', {
                        type: 'invalid',
                        message: t('validation.invalid_postcode') || t('validation.invalid')
                    })
                }
            } else {
                formMethods.setValue('customCity', '')
                setCustomCitiesState({loading: false, data: []})
            }
        }
    }, [customPostcode])

    useEffect(async () => {
        await formMethods.trigger('memberPhone')
    }, [memberPhone])


    useEffect(() => {
        switch (props.error) {
            case 'phone_to_many_times_used':
                if (memberAddress) {
                    formMethods.setError('memberPhone', {
                        type: 'phone_to_many_times_used',
                        message: t('error.phone_to_many_times_used')
                    })
                } else {
                    formMethods.setError('phone', {
                        type: 'phone_to_many_times_used',
                        message: t('error.phone_to_many_times_used')
                    })
                }
                window.scrollTo({top: 0, behavior: 'smooth'})
                break;
        }
    }, [props.error])


    useEffect(() => {
        if (props.customer?.title) {
            formMethods.setValue('title', civilityOptions.find(props.customer.title.value))
        }
        if (props.customer?.customTitle && !isEnabledByMarket('hideCivility')) {
            formMethods.setValue('customTitle', civilityOptions.find(props.customer.customTitle.value))
        }
    }, [store.civilities])

    useEffect(() => {
        if (props.customer?.country) {
            formMethods.setValue('country', countryOptions.findCountry(props.customer.country.value))
        }
    }, [props.country])

    useEffect(async () => {
        if (memberAddress) {
            formMethods.setValue('anotherAddress', false)
            if (!props.customer || (props.customer?.phone !== props.customer?.memberPhone)) {
                await memberPhoneMask.checkMemberPhone(memberAddress.value, props.addresses, props.customer)
            }
        }
    }, [memberAddress]);


    useEffect(() => {
        if (anotherAddress) {
            formMethods.setValue('memberAddress', null)
        }
    }, [anotherAddress]);


    useEffect(() => {
        const options = [];
        props.addresses.map(a => {
            options.push({
                label: `${a.address} ${a.address2}, ${a.postcode} ${a.city}`,
                value: a.id,
            })
        })
        setDeliveryOptions(options);

        if (props.customer?.memberAddress && !props.customer.anotherAddress) {
            formMethods.setValue('memberAddress', props.customer.memberAddress)
        }
    }, [props.addresses]);


    useEffect(() => {
        if (formMethods.formState.submitCount && Object.keys(formMethods.formState.errors).length) {
            scrollToError()
        }
    }, [formMethods.formState.submitCount, formMethods.formState.errors])

    const scrollToError = (el) => {
        if (!el)
            el = document.querySelector('.-invalid');
        if (el) {
            scrollVertically(el, 15, 'smooth');
        }
    }

    const getCities = async (postcode) => {
        return await MainActions.getCitiesByPostcode(postcode)
            .then(r => {
                const res = r.data
                if (res.status === 'success') {
                    return res.data.map(c => ({
                        label: localizeObject(c.city_title),
                        value: localizeObject(c.city_title),
                    }))
                } else {
                    return []
                }
            })
            .catch(e => [])
    }

    const handleChangeAddress = async (v) => {
        if (v) {
            formMethods.clearErrors('memberAddress');
            formMethods.clearErrors('anotherAddress');
        } else {
            // formMethods.setError('memberAddress', {type: 'required', message: t('validation.this_or', t('deliver_to_another_address'))});
            formMethods.setError('anotherAddress', {
                type: 'required',
                message: t('validation.this_or', t('choose_your_delivery_address'))
            });
        }

        formMethods.setValue('memberAddress', v);
    }


    const handleChangeAnotherAddress = (v) => {
        if (v) {
            formMethods.clearErrors('memberAddress');
            formMethods.clearErrors('anotherAddress');
        } else {
            formMethods.setError('memberAddress', {
                type: 'required',
                message: t('validation.this_or', t('deliver_to_another_address'))
            });
            formMethods.setError('anotherAddress', {
                type: 'required',
                message: t('validation.this_or', t('choose_your_delivery_address'))
            });
        }
        formMethods.setValue('anotherAddress', v);
    }


    const onSubmit = (data) => {
        if (data.memberAddress) {
            data.phone = data.memberPhone;
        }

        props.onSubmit(data);
    }

    return (
        <FormProvider {...formMethods}>
            <form id="customerDetailsForm" onSubmit={formMethods.handleSubmit(onSubmit)}>

                <div className="--mb4">
                    {!!props.nespressoMember && (
                        <div className="gridSm -full" hidden={!props.nespressoMember}>
                            <div className="col">

                                <Controller
                                    name="memberAddress"
                                    control={formMethods.control}
                                    rules={rules.memberAddress}
                                    render={({onChange, value}) => (
                                        <MySelect
                                            name="memberAddress"
                                            label={t('choose_your_delivery_address')}
                                            value={value}
                                            options={deliveryOptions}
                                            error={formMethods.errors.memberAddress}
                                            loading={props.loadingAddresses}
                                            onChange={handleChangeAddress}
                                            // nullable
                                        />
                                    )}
                                />


                                {!!memberAddress && (
                                    <Controller
                                        name="memberPhone"
                                        control={formMethods.control}
                                        rules={rules.phone}
                                        render={({onChange, value}) => fieldsConfigs.phone.mask_status ? (
                                            <MyInputMask
                                                name="memberPhone"
                                                label={t('phone')}
                                                mask={fieldsConfigs.phone.inputMask}
                                                formatChars={fieldsConfigs.phone.formatChars}
                                                value={value}
                                                error={formMethods.errors.memberPhone}
                                                formGroupClassNames="-ltr"
                                                onChange={onChange}
                                                inputRef={memberPhoneMask.ref}
                                            />
                                        ) : (
                                            <MyInput
                                                name="memberPhone"
                                                label={t('phone')}
                                                value={value}
                                                error={formMethods.errors.memberPhone}
                                                onChange={onChange}
                                                formGroupClassNames="-ltr"
                                                maxLength={rules.phone.maxLength?.value}
                                            />
                                        )}
                                    />
                                )}


                                <Controller
                                    name="anotherAddress"
                                    control={formMethods.control}
                                    rules={rules.anotherAddress}
                                    render={({onChange, value}) => (
                                        <MyCheckbox
                                            name="anotherAddress"
                                            label={t('deliver_to_another_address')}
                                            value={value}
                                            error={formMethods.errors.anotherAddress}
                                            onChange={handleChangeAnotherAddress}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    )}


                    {!!anotherAddress && (
                        <div className="--mb2">
                            <div className="--mb2">
                                <Controller
                                    name="companyAddress"
                                    control={formMethods.control}
                                    rules={rules.companyAddress}
                                    render={({onChange, value}) => (
                                        <div className="--mb2">
                                            <MyCheckbox
                                                name="companyAddress"
                                                label={t('company_address')}
                                                value={value}
                                                error={formMethods.errors.companyAddress}
                                                onChange={onChange}
                                            />
                                        </div>
                                    )}
                                />

                                <div className="gridSm -full">
                                    <div className="col">
                                        <div hidden={!companyAddress}>
                                            <Controller
                                                name="companyName"
                                                control={formMethods.control}
                                                rules={rules.companyName}
                                                render={({onChange, value}) => (
                                                    <MyInput
                                                        name="companyName"
                                                        label={t('company_name')}
                                                        value={value}
                                                        error={formMethods.errors.companyName}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                            {isEnabledByMarket('profession') && companyAddress && (
                                                <Controller
                                                    name="profession"
                                                    control={formMethods.control}
                                                    rules={rules.profession}
                                                    render={({onChange, value}) => (
                                                        <MyAsyncSelect
                                                            name="profession"
                                                            label={t('profession')}
                                                            value={value}
                                                            loadOptions={professionOptions.startsWith}
                                                            error={formMethods.errors.profession}
                                                            onChange={onChange}
                                                            isSearchable
                                                        />
                                                    )}
                                                />
                                            )}
                                        </div>
                                        {!isEnabledByMarket('hideCivility') && (
                                            <div hidden={companyAddress}>
                                                <Controller
                                                    name="title"
                                                    control={formMethods.control}
                                                    rules={rules.title}
                                                    render={({onChange, value}) => (
                                                        <MySelect
                                                            name="title"
                                                            label={t('title')}
                                                            value={value}
                                                            options={civilityOptions.options}
                                                            // nullable={true}
                                                            error={formMethods.errors.title}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )}
                                        {!!fieldsConfigs.taxId.status && !companyAddress && (
                                            <Controller
                                                name="taxId"
                                                control={formMethods.control}
                                                rules={rules.taxId}
                                                render={({onChange, value}) => fieldsConfigs.taxId.mask_status ? (
                                                    <MyInputMask
                                                        name="taxId"
                                                        label={t('tax_id')}
                                                        mask={fieldsConfigs.taxId.inputMask}
                                                        value={value}
                                                        error={formMethods.errors.taxId}
                                                        onChange={onChange}
                                                    />
                                                ) : (
                                                    <MyInput
                                                        name="taxId"
                                                        label={t('tax_id')}
                                                        value={value}
                                                        error={formMethods.errors.taxId}
                                                        onChange={onChange}
                                                        maxLength={rules.taxId.maxLength?.value}
                                                    />
                                                )}
                                            />
                                        )}
                                        {!!fieldsConfigs.companyTaxId.status && companyAddress && (
                                            <Controller
                                                name="taxId"
                                                control={formMethods.control}
                                                rules={rules.companyTaxId}
                                                render={({
                                                             onChange,
                                                             value
                                                         }) => fieldsConfigs.companyTaxId.mask_status ? (
                                                    <MyInputMask
                                                        name="taxId"
                                                        label={t('company_tax_id') || t('tax_id')}
                                                        mask={fieldsConfigs.companyTaxId.inputMask}
                                                        value={value}
                                                        error={formMethods.errors.taxId}
                                                        onChange={onChange}
                                                    />
                                                ) : (
                                                    <MyInput
                                                        name="taxId"
                                                        label={t('company_tax_id') || t('tax_id')}
                                                        value={value}
                                                        error={formMethods.errors.taxId}
                                                        onChange={onChange}
                                                        maxLength={rules.companyTaxId.maxLength?.value}
                                                    />
                                                )}
                                            />
                                        )}
                                    </div>
                                </div>


                                <div className="gridSm">
                                    <div className="col">
                                        <Controller
                                            name="name"
                                            control={formMethods.control}
                                            rules={rules.name}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="name"
                                                    label={t('name')}
                                                    value={value}
                                                    error={formMethods.errors.name}
                                                    onChange={onChange}
                                                    maxLength={rules.name.maxLength?.value}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col">
                                        <Controller
                                            name="surname"
                                            control={formMethods.control}
                                            rules={rules.surname}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="surname"
                                                    label={t('surname')}
                                                    value={value}
                                                    error={formMethods.errors.surname}
                                                    onChange={onChange}
                                                    maxLength={rules.surname.maxLength?.value}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="gridSm">
                                    <div className="col">
                                        <Controller
                                            name="postcode"
                                            control={formMethods.control}
                                            rules={rules.postcode}
                                            render={({onChange, value}) => fieldsConfigs.postcode.mask_status ? (
                                                <MyInputMask
                                                    name="postcode"
                                                    label={t('postcode', '(A1B 2C3)')}
                                                    mask={fieldsConfigs.postcode.inputMask}
                                                    value={value}
                                                    error={formMethods.errors.postcode}
                                                    formGroupClassNames="-ltr"
                                                    onChange={onChange}
                                                    forceUppercase={isEnabledByMarket("autoUppercasePostCode")}
                                                />
                                            ) : (
                                                <MyInput
                                                    name="postcode"
                                                    label={t('postcode', '(A1B 2C3)')}
                                                    value={value}
                                                    error={formMethods.errors.postcode}
                                                    onChange={onChange}
                                                    formGroupClassNames="-ltr"
                                                    maxLength={rules.postcode.maxLength?.value}
                                                    forceUppercase={isEnabledByMarket("autoUppercasePostCode")}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col">
                                        <Controller
                                            name="city"
                                            control={formMethods.control}
                                            rules={rules.city}
                                            render={({onChange, value}) => marketConfig.post_code_automatic_city ? (
                                                <MySelect
                                                    name="city"
                                                    label={t('city')}
                                                    value={value}
                                                    options={citiesState.data}
                                                    error={formMethods.errors.city}
                                                    onChange={onChange}
                                                    maxLength={rules.city.maxLength?.value}
                                                    loading={citiesState.loading}
                                                    parseValueToString
                                                />
                                            ) : (
                                                <MyInput
                                                    name="city"
                                                    label={t('city')}
                                                    value={value}
                                                    error={formMethods.errors.city}
                                                    onChange={onChange}
                                                    maxLength={rules.city.maxLength?.value}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="gridSm -full">
                                    <div className="col">
                                        <Controller
                                            name="address"
                                            control={formMethods.control}
                                            rules={rules.address}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="address"
                                                    label={t('address_line_1')}
                                                    value={value}
                                                    error={formMethods.errors.address}
                                                    onChange={onChange}
                                                    maxLength={rules.address.maxLength?.value}
                                                />
                                            )}
                                        />

                                        {!!fieldsConfigs.address2.status && (
                                            <Controller
                                                name="address2"
                                                control={formMethods.control}
                                                rules={rules.address2}
                                                render={({onChange, value}) => (
                                                    <MyInput
                                                        name="address2"
                                                        label={t('address_line_2')}
                                                        value={value}
                                                        error={formMethods.errors.address2}
                                                        onChange={onChange}
                                                        maxLength={rules.address2.maxLength?.value}
                                                    />
                                                )}
                                            />
                                        )}

                                        {isEnabledByMarket('region') && (
                                            <Controller
                                                name="region"
                                                control={formMethods.control}
                                                rules={rules.region}
                                                render={({onChange, value}) => (
                                                    <MyAsyncSelect
                                                        name="region"
                                                        label={t('region')}
                                                        value={value}
                                                        loadOptions={regionOptions.startsWith}
                                                        error={formMethods.errors.region}
                                                        onChange={onChange}
                                                        isSearchable
                                                    />
                                                )}
                                            />
                                        )}

                                        {!isEnabledByMarket('hideCountryField') && (
                                            <Controller
                                                name="country"
                                                control={formMethods.control}
                                                rules={rules.country}
                                                render={({onChange, value}) => (
                                                    <MySelect
                                                        name="country"
                                                        label={t('country')}
                                                        value={value}
                                                        options={countryOptions.countryOptions}
                                                        error={formMethods.errors.country}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        )}

                                        {!!props.user && !props.nespressoMember && (
                                            <Controller
                                                name="lang"
                                                control={formMethods.control}
                                                rules={rules.lang}
                                                render={({onChange, value}) => (
                                                    <MySelect
                                                        name="lang"
                                                        label={t('lang')}
                                                        value={value}
                                                        options={countryOptions.languageOptions}
                                                        error={formMethods.errors.lang}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        )}

                                        <Controller
                                            name="phone"
                                            control={formMethods.control}
                                            rules={rules.phone}
                                            render={({onChange, value}) => fieldsConfigs.phone.mask_status ? (
                                                <MyInputMask
                                                    name="phone"
                                                    label={t('phone', '(514-123-4567)')}
                                                    mask={fieldsConfigs.phone.inputMask}
                                                    formatChars={fieldsConfigs.phone.formatChars}
                                                    value={value}
                                                    error={formMethods.errors.phone}
                                                    formGroupClassNames="-ltr"
                                                    onChange={onChange}
                                                />
                                            ) : (
                                                <MyInput
                                                    name="phone"
                                                    label={t('phone')}
                                                    value={value}
                                                    error={formMethods.errors.phone}
                                                    onChange={onChange}
                                                    formGroupClassNames="-ltr"
                                                    maxLength={rules.phone.maxLength?.value}
                                                />
                                            )}
                                        />

                                        {!!fieldsConfigs.phoneMobile.status && (
                                            <Controller
                                                name="phoneMobile"
                                                control={formMethods.control}
                                                rules={rules.phoneMobile}
                                                render={({onChange, value}) => fieldsConfigs.phoneMobile.mask_status ? (
                                                    <MyInputMask
                                                        name="phoneMobile"
                                                        label={t('phone_mobile')}
                                                        mask={fieldsConfigs.phoneMobile.inputMask}
                                                        formatChars={fieldsConfigs.phoneMobile.formatChars}
                                                        value={value}
                                                        error={formMethods.errors.phoneMobile}
                                                        formGroupClassNames="-ltr"
                                                        onChange={onChange}
                                                    />
                                                ) : (
                                                    <MyInput
                                                        name="phoneMobile"
                                                        label={t('phone_mobile')}
                                                        value={value}
                                                        error={formMethods.errors.phoneMobile}
                                                        onChange={onChange}
                                                        formGroupClassNames="-ltr"
                                                        maxLength={rules.phoneMobile.maxLength?.value}
                                                    />
                                                )}
                                            />
                                        )}

                                        {!!fieldsConfigs.phoneHome.status && (
                                            <Controller
                                                name="phoneHome"
                                                control={formMethods.control}
                                                rules={rules.phoneHome}
                                                render={({onChange, value}) => fieldsConfigs.phoneHome.mask_status ? (
                                                    <MyInputMask
                                                        name="phoneHome"
                                                        label={t('phone_home')}
                                                        mask={fieldsConfigs.phoneHome.inputMask}
                                                        formatChars={fieldsConfigs.phoneHome.formatChars}
                                                        value={value}
                                                        error={formMethods.errors.phoneHome}
                                                        formGroupClassNames="-ltr"
                                                        onChange={onChange}
                                                    />
                                                ) : (
                                                    <MyInput
                                                        name="phoneHome"
                                                        label={t('phone_home')}
                                                        value={value}
                                                        error={formMethods.errors.phoneHome}
                                                        onChange={onChange}
                                                        formGroupClassNames="-ltr"
                                                        maxLength={rules.phoneHome.maxLength?.value}
                                                    />
                                                )}
                                            />
                                        )}
                                    </div>
                                </div>
                                {!props.nespressoMember && (
                                    <div className="gridSm">
                                        <div className="col">
                                            <Controller
                                                name="email"
                                                control={formMethods.control}
                                                rules={rules.email}
                                                render={({onChange, value}) => (
                                                    <MyInput
                                                        name="email"
                                                        label={t('email')}
                                                        value={value}
                                                        error={formMethods.errors.email}
                                                        onChange={onChange}
                                                        inputProps={{
                                                            onCopy: e => countryConfig.custom_format?.custom_email_format && !Number(countryConfig.custom_format.custom_email_format.copy) ? e.preventDefault() : undefined,
                                                            onPaste: e => countryConfig.custom_format?.custom_email_format && !Number(countryConfig.custom_format.custom_email_format.past) ? e.preventDefault() : undefined,
                                                        }}/>
                                                )}
                                            />
                                        </div>
                                        <div className="col">
                                            <Controller
                                                name="reEmail"
                                                control={formMethods.control}
                                                rules={rules.reEmail}
                                                render={({onChange, value}) => (
                                                    <MyInput
                                                        name="reEmail"
                                                        label={t('re_email')}
                                                        value={value}
                                                        error={formMethods.errors.reEmail}
                                                        onChange={onChange} inputProps={{
                                                        onCopy: e => countryConfig.custom_format?.custom_re_email_format && !Number(countryConfig.custom_format.custom_re_email_format.copy) ? e.preventDefault() : undefined,
                                                        onPaste: e => countryConfig.custom_format?.custom_re_email_format && !Number(countryConfig.custom_format.custom_re_email_format.past) ? e.preventDefault() : undefined,
                                                    }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                )}
                                {!props.nespressoMember && !props.user && (
                                    <div className="gridSm">
                                        <div className="col">
                                            <Controller
                                                name="password"
                                                control={formMethods.control}
                                                rules={rules.password}
                                                render={({onChange, value}) => (
                                                    <MyInput
                                                        name="password"
                                                        type="password"
                                                        label={t('password')}
                                                        value={value}
                                                        error={formMethods.errors.password}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="col">
                                            <Controller
                                                name="rePassword"
                                                control={formMethods.control}
                                                rules={rules.rePassword}
                                                render={({onChange, value}) => (
                                                    <MyInput
                                                        name="rePassword"
                                                        type="password"
                                                        label={t('re_password')}
                                                        value={value}
                                                        error={formMethods.errors.rePassword}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>


                            {!props.nespressoMember && (
                                <>
                                    <Controller
                                        name="withCustomAddress"
                                        control={formMethods.control}
                                        rules={rules.withCustomAddress}
                                        render={({onChange, value}) => (
                                            <div className="--mb2">
                                                <MyCheckbox
                                                    name="withCustomAddress"
                                                    label={t('deliver_to_another_address')}
                                                    value={value}
                                                    error={formMethods.errors.withCustomAddress}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        )}
                                    />

                                    {!!withCustomAddress && (
                                        <div>
                                            <Controller
                                                name="customCompanyAddress"
                                                control={formMethods.control}
                                                rules={rules.customCompanyAddress}
                                                render={({onChange, value}) => (
                                                    <div className="--mb2">
                                                        <MyCheckbox
                                                            name="customCompanyAddress"
                                                            label={t('company_address')}
                                                            value={value}
                                                            error={formMethods.errors.customCompanyAddress}
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                )}
                                            />

                                            <div className="gridSm -full">
                                                <div className="col">
                                                    <div hidden={!customCompanyAddress}>
                                                        <Controller
                                                            name="customCompanyName"
                                                            control={formMethods.control}
                                                            rules={rules.customCompanyName}
                                                            render={({onChange, value}) => (
                                                                <MyInput
                                                                    name="customCompanyName"
                                                                    label={t('company_name')}
                                                                    value={value}
                                                                    error={formMethods.errors.customCompanyName}
                                                                    onChange={onChange}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    {!isEnabledByMarket('hideCivility') && (
                                                        <div hidden={customCompanyAddress}>
                                                            <Controller
                                                                name="customTitle"
                                                                control={formMethods.control}
                                                                rules={rules.customTitle}
                                                                render={({onChange, value}) => (
                                                                    <MySelect
                                                                        name="customTitle"
                                                                        label={t('title')}
                                                                        value={value}
                                                                        options={civilityOptions.options}
                                                                        error={formMethods.errors.customTitle}
                                                                        onChange={onChange}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    )}

                                                    {/*{!!marketConfig?.company_tax_id && (*/}
                                                    {/*    <Controller*/}
                                                    {/*        name="customTaxId"*/}
                                                    {/*        control={formMethods.control}*/}
                                                    {/*        rules={rules.taxId}*/}
                                                    {/*        render={({onChange, value}) => Number(countryConfig.custom_format?.custom_tax_id_format?.mask_status) ? (*/}
                                                    {/*            <MyInputMask*/}
                                                    {/*                name="customTaxId"*/}
                                                    {/*                label={t('tax_id')}*/}
                                                    {/*                mask={TAX_ID_MASK.inputMask}*/}
                                                    {/*                value={value}*/}
                                                    {/*                error={formMethods.errors.customTaxId}*/}
                                                    {/*                onChange={onChange}*/}
                                                    {/*            />*/}
                                                    {/*        ) : (*/}
                                                    {/*            <MyInput*/}
                                                    {/*                name="customTaxId"*/}
                                                    {/*                label={t('tax_id')}*/}
                                                    {/*                value={value}*/}
                                                    {/*                error={formMethods.errors.customTaxId}*/}
                                                    {/*                onChange={onChange}*/}
                                                    {/*            />*/}
                                                    {/*        )}*/}
                                                    {/*    />*/}
                                                    {/*)}*/}
                                                </div>
                                            </div>


                                            <div className="gridSm">
                                                <div className="col">
                                                    <Controller
                                                        name="customName"
                                                        control={formMethods.control}
                                                        rules={rules.name}
                                                        render={({onChange, value}) => (
                                                            <MyInput
                                                                name="customName"
                                                                label={t('name')}
                                                                value={value}
                                                                error={formMethods.errors.customName}
                                                                onChange={onChange}
                                                                maxLength={rules.name.maxLength?.value}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <Controller
                                                        name="customSurname"
                                                        control={formMethods.control}
                                                        rules={rules.surname}
                                                        render={({onChange, value}) => (
                                                            <MyInput
                                                                name="customSurname"
                                                                label={t('surname')}
                                                                value={value}
                                                                error={formMethods.errors.customSurname}
                                                                onChange={onChange}
                                                                maxLength={rules.surname.maxLength?.value}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="gridSm">
                                                <div className="col">
                                                    <Controller
                                                        name="customPostcode"
                                                        control={formMethods.control}
                                                        rules={rules.postcode}
                                                        render={({
                                                                     onChange,
                                                                     value
                                                                 }) => fieldsConfigs.postcode.mask_status ? (
                                                            <MyInputMask
                                                                name="customPostcode"
                                                                label={t('postcode')}
                                                                mask={fieldsConfigs.postcode.inputMask}
                                                                value={value}
                                                                error={formMethods.errors.customPostcode}
                                                                formGroupClassNames="-ltr"
                                                                onChange={onChange}
                                                                forceUppercase={isEnabledByMarket("autoUppercasePostCode")}
                                                            />
                                                        ) : (
                                                            <MyInput
                                                                name="customPostcode"
                                                                label={t('postcode')}
                                                                value={value}
                                                                error={formMethods.errors.customPostcode}
                                                                onChange={onChange}
                                                                formGroupClassNames="-ltr"
                                                                maxLength={rules.postcode.maxLength?.value}
                                                                forceUppercase={isEnabledByMarket("autoUppercasePostCode")}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <Controller
                                                        name="customCity"
                                                        control={formMethods.control}
                                                        rules={rules.city}
                                                        render={({
                                                                     onChange,
                                                                     value
                                                                 }) => marketConfig.post_code_automatic_city ? (
                                                            <MySelect
                                                                name="customCity"
                                                                label={t('city')}
                                                                value={value}
                                                                options={customCitiesState.data}
                                                                error={formMethods.errors.customCity}
                                                                onChange={onChange}
                                                                maxLength={rules.city.maxLength?.value}
                                                                loading={customCitiesState.loading}
                                                                parseValueToString
                                                            />
                                                        ) : (
                                                            <MyInput
                                                                name="customCity"
                                                                label={t('city')}
                                                                value={value}
                                                                error={formMethods.errors.customCity}
                                                                onChange={onChange}
                                                                maxLength={rules.city.maxLength?.value}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="gridSm -full">
                                                <div className="col">
                                                    <Controller
                                                        name="customAddress"
                                                        control={formMethods.control}
                                                        rules={rules.address}
                                                        render={({onChange, value}) => (
                                                            <MyInput
                                                                name="customAddress"
                                                                label={t('address_line_1')}
                                                                value={value}
                                                                error={formMethods.errors.customAddress}
                                                                onChange={onChange}
                                                                maxLength={rules.address.maxLength?.value}
                                                            />
                                                        )}
                                                    />

                                                    {!!fieldsConfigs.address2.status && (
                                                        <Controller
                                                            name="customAddress2"
                                                            control={formMethods.control}
                                                            rules={rules.address2}
                                                            render={({onChange, value}) => (
                                                                <MyInput
                                                                    name="customAddress2"
                                                                    label={t('address_line_2')}
                                                                    value={value}
                                                                    error={formMethods.errors.customAddress2}
                                                                    onChange={onChange}
                                                                    maxLength={rules.address2.maxLength?.value}
                                                                />
                                                            )}
                                                        />
                                                    )}

                                                    {isEnabledByMarket('region') && (
                                                        <Controller
                                                            name="customRegion"
                                                            control={formMethods.control}
                                                            rules={rules.customRegion}
                                                            render={({onChange, value}) => (
                                                                <MyAsyncSelect
                                                                    name="customRegion"
                                                                    label={t('region')}
                                                                    value={value}
                                                                    loadOptions={regionOptions.startsWith}
                                                                    error={formMethods.errors.customRegion}
                                                                    onChange={onChange}
                                                                    isSearchable
                                                                />
                                                            )}
                                                        />
                                                    )}

                                                    {!!marketConfig?.other_delivery_phone_enabled && (
                                                        <>
                                                            <Controller
                                                                name="customPhone"
                                                                control={formMethods.control}
                                                                rules={rules.phone}
                                                                render={({
                                                                             onChange,
                                                                             value
                                                                         }) => fieldsConfigs.phone.mask_status ? (
                                                                    <MyInputMask
                                                                        name="customPhone"
                                                                        label={t('phone')}
                                                                        mask={fieldsConfigs.phone.inputMask}
                                                                        formatChars={fieldsConfigs.phone.formatChars}
                                                                        value={value}
                                                                        error={formMethods.errors.customPhone}
                                                                        formGroupClassNames="-ltr"
                                                                        onChange={onChange}
                                                                    />
                                                                ) : (
                                                                    <MyInput
                                                                        name="customPhone"
                                                                        label={t('phone')}
                                                                        value={value}
                                                                        error={formMethods.errors.customPhone}
                                                                        onChange={onChange}
                                                                        formGroupClassNames="-ltr"
                                                                        maxLength={rules.phone.maxLength?.value}
                                                                    />
                                                                )}
                                                            />

                                                            {!!fieldsConfigs.phoneMobile.status && (
                                                                <Controller
                                                                    name="customPhoneMobile"
                                                                    control={formMethods.control}
                                                                    rules={rules.phoneMobile}
                                                                    render={({
                                                                                 onChange,
                                                                                 value
                                                                             }) => fieldsConfigs.phoneMobile.mask_status ? (
                                                                        <MyInputMask
                                                                            name="customPhoneMobile"
                                                                            label={t('phone_mobile')}
                                                                            mask={fieldsConfigs.phoneMobile.inputMask}
                                                                            formatChars={fieldsConfigs.phoneMobile.formatChars}
                                                                            value={value}
                                                                            error={formMethods.errors.customPhoneMobile}
                                                                            formGroupClassNames="-ltr"
                                                                            onChange={onChange}
                                                                        />
                                                                    ) : (
                                                                        <MyInput
                                                                            name="customPhoneMobile"
                                                                            label={t('phone_mobile')}
                                                                            value={value}
                                                                            error={formMethods.errors.customPhoneMobile}
                                                                            onChange={onChange}
                                                                            formGroupClassNames="-ltr"
                                                                            maxLength={rules.phoneMobile.maxLength?.value}
                                                                        />
                                                                    )}
                                                                />
                                                            )}

                                                            {!!fieldsConfigs.phoneHome.status && (
                                                                <Controller
                                                                    name="customPhoneHome"
                                                                    control={formMethods.control}
                                                                    rules={rules.phoneHome}
                                                                    render={({
                                                                                 onChange,
                                                                                 value
                                                                             }) => fieldsConfigs.phoneHome.mask_status ? (
                                                                        <MyInputMask
                                                                            name="customPhoneHome"
                                                                            label={t('phone_home')}
                                                                            mask={fieldsConfigs.phoneHome.inputMask}
                                                                            formatChars={fieldsConfigs.phoneHome.formatChars}
                                                                            value={value}
                                                                            error={formMethods.errors.customPhoneHome}
                                                                            formGroupClassNames="-ltr"
                                                                            onChange={onChange}
                                                                        />
                                                                    ) : (
                                                                        <MyInput
                                                                            name="customPhoneHome"
                                                                            label={t('phone_home')}
                                                                            value={value}
                                                                            error={formMethods.errors.customPhoneHome}
                                                                            onChange={onChange}
                                                                            formGroupClassNames="-ltr"
                                                                            maxLength={rules.phoneHome.maxLength?.value}
                                                                        />
                                                                    )}
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>


                <BenefitsInfo/>


                <div className="--mb4">
                    <FormFragmentBenefits
                        config={{
                            email: props.plan ? props.plan.product_plan_marketing_email_visible : props.promotion?.promotion_marketing_email_visible,
                            mobile: props.plan ? props.plan.product_plan_marketing_mobile_visible : props.promotion?.promotion_marketing_mobile_visible,
                            phone: props.plan ? props.plan.product_plan_marketing_phone_visible : props.promotion?.promotion_marketing_phone_visible,
                            post: props.plan ? props.plan.product_plan_marketing_post_visible : props.promotion?.promotion_marketing_post_visible,
                            required: props.plan ? props.plan.product_plan_marketing_required : props.promotion?.promotion_marketing_required,
                            selectAllEnabled: props.plan ? props.plan.product_plan_marketing_select_all_benefits_enabled : props.promotion?.promotion_marketing_select_all_benefits_enabled,
                            configOptionsVisibility: marketConfig?.user_marketing_options_visibility,
                            userConsents: props.nespressoMember?.consents,
                        }}
                    />
                </div>


                <TermsInfo/>


                {!!marketConfig.terms_and_conditions_preview && (
                    <div className="gridSm -full --mb4">
                        <iframe
                            src={termsFileUrl ?? t('terms_and_conditions_url')}
                            style={{width: '100%', height: 400, border: 'none'}}
                        />
                        <div/>
                    </div>
                )}


                <div className="--mb4">
                    <Controller
                        name="termsAndConditions"
                        control={formMethods.control}
                        rules={rules.termsAndConditions}
                        render={({onChange, value}) => (
                            <MyCheckbox
                                name="termsAndConditions"
                                value={value}
                                error={formMethods.errors.termsAndConditions}
                                onChange={onChange}
                            >
                            <span
                                className="--bold"
                                dangerouslySetInnerHTML={{__html: t('terms_and_conditions_confirmation', termsFileUrl ?? t('terms_and_conditions_url'))}}
                            />
                            </MyCheckbox>
                        )}
                    />

                    <Controller
                        name="privacyPolicy"
                        control={formMethods.control}
                        rules={rules.privacyPolicy}
                        render={({onChange, value}) => (
                            <MyCheckbox
                                name="privacyPolicy"
                                value={value}
                                error={formMethods.errors.privacyPolicy}
                                onChange={onChange}
                            >
                            <span
                                className="--bold"
                                dangerouslySetInnerHTML={{__html: t('privacy_policy_confirmation', privacyFileUrl ?? t('privacy_policy_url'))}}
                            />
                            </MyCheckbox>
                        )}
                    />
                </div>


                {!props.nespressoMember && (
                    <div className="--mb4">
                        <Controller
                            name="recaptchaToken"
                            control={formMethods.control}
                            rules={rules.recaptchaToken}
                            render={({onChange, value}) => (
                                <RecaptchaV2
                                    value={value}
                                    onChange={onChange}
                                    error={formMethods.errors.recaptchaToken}
                                />
                            )}
                        />
                    </div>
                )}


                {!!Object.keys(formMethods.formState.errors)?.length && (
                    <p className="par-2 --error --mb4">{t('fill_all_required_fields')}</p>
                )}


                <div className="step__nav">
                    <MyLink route={props.stepNavigation.prev()} disabled={props.loading}>
                        <Button
                            type="primary-outline"
                            label={t('back')}
                            disabled={props.loading}
                        />
                    </MyLink>
                    <Button
                        btnType="submit"
                        type="primary"
                        label={!props.nespressoMember && !props.user ? t('save_my_data') : t('continue')}
                        disabled={props.loading}
                        onClick={formMethods.handleSubmit(onSubmit)}
                    />
                </div>
            </form>
        </FormProvider>
    );
}


