import * as orderActions from "../actions/OrderActions";
import {ApiException} from "../errors";


/**
 * Create account
 * @param {object} data Customer data
 * @param {string} recaptchaToken Recaptcha token
 * @returns {Promise<object>}
 * @throws {ApiException}
 */
export async function createAccount(data, recaptchaToken) {
    const payload = {customer: data, recaptcha_token: recaptchaToken}

    return await orderActions.createAccount(payload)
        .then(async r => {
            const res = r.data
            if (res.status === 'success') {
                return {
                    account: res.data,
                    linked: true,
                }
            } else {
                if (res.messages[0] === 'linking_error') {
                    return {
                        account: res.data,
                        linked: false,
                    }
                }
                throw new ApiException('Something gone wrong', res, res.messages[0])
            }
        })
        .catch(err => {
            if (err instanceof ApiException)
                throw err
            throw new ApiException('Unknown error', err)
        })
}


export async function linkAccount(data, memberNumber, config = {}) {
    console.log(config)
    return await linkAction(data, memberNumber, 0, config)
}


export async function linkAction(data, memberNumber, attempt = 0, config = {}) {
    config = {timeout: 20000, attempts: 10, ...config}
    attempt++;

    console.log('attempt: ', attempt)

    return await orderActions.linkMember(data, memberNumber)
        .then(async r => {
            const res = r.data;
            if (res.status === 'success') {
                return res.data;
            } else if(!res.data.retry) {
                return null;
            } else {
                if (!res.data?.order && attempt < config.attempts) {
                    await new Promise(resolve => setTimeout(resolve, config.timeout))
                    return await linkAction(data, memberNumber, attempt, config)
                }
            }
            return null
        })
        .catch(err => {
            throw new ApiException('Something gone wrong', err)
        })
}


export async function makeQuotation(orderId) {
    const payload = {id: orderId}
    return await orderActions.makeQuotation(payload)
        .then(r => {
            const res = r.data
            if (res.status === 'success') {
                return res.data
            }
            throw new ApiException('Cannot make quotation', res)
        })
        .catch(err => {
            if (err instanceof ApiException)
                throw err
            throw new ApiException('Unknown error', err)
        })
}

export async function updateOrder(token, data) {
    return await orderActions.updateOrder(token, data)
        .then(createOrderHandleSuccess)
        .catch(createOrderHandleError)
}

export async function createOrder(data) {
    return await orderActions.finalize(data)
        .then(createOrderHandleSuccess)
        .catch(createOrderHandleError)
}


export async function createOrderReseller(data) {
    return await orderActions.finalizeReseller(data)
        .then(createOrderHandleSuccess)
        .catch(createOrderHandleError)
}


export async function cartQuotation(data) {
    return await orderActions.cartQuotation(data)
        .then(r => {
            const res = r.data
            if (res.status === 'success') {
                return res.data
            }
            throw new ApiException('Something gone wrong', res, res.messages[0])
        })
        .catch(e => {
            if (e instanceof ApiException)
                throw e
            throw new ApiException('Unknown error', e)
        })
}

export async function memberCredit(data) {
    return await orderActions.checkCredit(data)
        .then(r => {
            const res = r.data
            if (res.status === 'success') {
                return res.data
            }
            throw new ApiException('Something gone wrong', res, res.messages[0])
        })
        .catch(e => {
            if (e instanceof ApiException)
                throw e
            throw new ApiException('Unknown error', e)
        })
}

async function createOrderHandleSuccess(r) {
    const res = r.data;
    if (res.status === 'success') {
        return {
            data: res.data,
            error: undefined,
            errorLog: undefined,
        }
    } else {
        if (res.messages[0] === 'order_exists') {
            return {
                data: null,
                error: res.messages[0],
                errorLog: res,
            }
        } else if (res.data?.order) {
            return {
                data: res.data,
                error: res.messages[0],
                errorLog: res,
            }
        }
        if (res.messages?.length) {
            throw new ApiException('Something gone wrong', res, res.messages[0])
        }
    }
}

async function createOrderHandleError(err) {
    if (err instanceof ApiException)
        throw err
    throw new ApiException('Unknown error', err)
}



